
// IMPORT FONTS from TypeKit 
// (Account Larissa)

@import url("https://use.typekit.net/vuw2lfg.css");

// ... Currently loaded (or see css above)

// Droid Sans Mono 
// ==============
// font-family: droid-sans-mono, monospace;
// font-weight: 400;
// font-style: normal;

// PT Sans Pro
// ===========
// font-family: pt-sans-pro, sans-serif;
// font-weight: 300;
// font-style: normal;

// font-weight: 300;
// font-style: italic;

// font-weight: 400;
// font-style: normal;

// font-weight: 700;
// font-style: normal;


// PT Sans Pro Narrow
// ==================
// font-family: pt-sans-pro-narrow, sans-serif;
// font-weight: 400;
// font-style: normal;

:root {

  /* Font variables */
  --font-family-narrow:  "Pt Sans Pro Narrow", pt-sans-pro-narrow, "Arial Narrow", sans-serif;
  --font-family-monospace:  "Droid Sans Mono", droid-sans-mono, monospace; 
  --font-family-sans-serif:  "PT Sans Pro", pt-sans-pro, 'Helvetica', sans-serif; ;

}

html, body {
  height: 100%;
  box-sizing: border-box;
  padding:0;
  margin: 0;
  color: var(--color-text);
  -webkit-overflow-scrolling: touch; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--text-body-font-weight);
  font-family: var(--font-family-sans-serif);
}

body {
  background-color: var(--color-bg);
  overflow-x: hidden;

  &.tool{
    background-color: #2B2B2B;
    color: #fff;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background: var(--color-hl, #ff0);
}


#__next {
  height: 100%;
}

.svg {
  -webkitPrint-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important;                 /* Firefox 48 – 96 */
  print-color-adjust: exact !important;    
}

sup {
  transform: translateY(2px);
}

a {
  color: var(--color-link);
  text-decoration-color: var(--color-link);
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0.5px;

  &:link,
  &:visited,
  &:active {
    color: var(--color-link);
    text-decoration-color: var(--color-link);
  }
}

@media (any-pointer: fine) {
  a {
    transition: color 0.3s;
    &:hover {
      color: var(--color-link-hover);
    }
  }
}

ul,ol {
  padding: 0 0 0 1.5em;
  margin: 0 0 1em 0;
  li {
    margin: 0 0 0.3em 0;
    padding: 0;
  }
}

ul li {
  list-style-type: '● ';
  &::marker {
    font-size:  0.8em;
  }
}

strong {
  font-weight: calc(var(--text-body-font-weight) + 300)
}

::placeholder,
input::placeholder  {
  font-family: var(--font-family-monospace);
  line-height: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  letter-spacing: inherit !important;
  color: var(--color-link-hover) !important;
}

a,
button,
input,
select,
video {
  &:focus-within {
    outline-width: 3px;
    outline-color: transparent ;
    outline-offset: 3px;
    outline-style: solid;
  }
}

.tabbed a,
.tabbed button,
.tabbed input,
.tabbed select {
  &:focus-within {
    outline-width: 3px;
    outline-color: var(--color-focus-outline, #00f);
    outline-offset: 3px;
    outline-style: solid;
  }
}

.flex-content {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.html {
    img {
      width: 100% !important;
      height: auto !important;
    }

    iframe {
      width: 100%;
    }

    a {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-color: #000;
      text-underline-offset: 2px;
      text-decoration-thickness: 0.5px;
      transition: color 0.3s, text-decoration-color 0.3s;
    }
  }

  @media (any-pointer: fine) {
    &.html {
      a {
        &:hover {
          color: #555;
          text-decoration-color: #555;
        }
      }
    }
  }
}

@media print {
  * {
    color: #000 !important;
  }

  a {
    color: #000 !important;
    text-decoration: underline !important;
  }
}